import React from 'react'
import '../App.css';
import TSiteHeader from '../components/t/TSiteHeader'

const InfoPage = () => {
    return (
      <>
          <TSiteHeader
              breadCrumbItems={[]}
              pageTitle={"General Information"}
          />
          <div style={{
              // backgroundColor: 'white',
              // border: '2px solid rgba(0, 0, 100, 1)',
              // borderRadius: '10px',
              // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              // overflow: 'hidden',
              // width: '100%',
              // padding: '10px 20px 20px 20px'
          }}>
              <div style={{marginBottom: '5em'}}>

                  <p>
                      The Product Carbon Footprint Calculator was developed for Koenig & Bauer's drupa 2024 appearance.
                      The
                      concept, product carbon footprint calculation and software development were developed and
                      implemented
                      by Telusio.
                  </p>
                  <h2>Project partner </h2>
                  <p>
                      Koenig & Bauer AG <br/>
                      Friedrich-Koenig-Straße 4 <br/>
                      97080 Würzburg
                  </p>
                  <h2>Project supervisor</h2>
                  <p>
                      Telusio GmbH <br/>
                      Clemensstr. 18 <br/>
                      80803 Munich <br/>
                      www.telusio.com
                  </p>
              </div>


              <h1>Further Information</h1>
              <h2>Greenhouse gases</h2>
              <p>
                  This Product Carbon Footprint covers the greenhouse gases identified in the Kyoto Protocol, including
                  carbon dioxide, methane, nitrous oxide, perfluorocarbons, hydrofluorocarbons and sulphur hexafluoride.
                  Due
                  to their different global warming potentials (GWP), these gases are converted into CO2 equivalents
                  (CO2e)
                  to enable better comparability.
              </p>
              <h2>Included locations</h2>
              <p>
                  Radebeul, Germany
              </p>
              <h2>Type of inventory</h2>
              <p>
                  Cradle-to-customer
              </p>
              <h2>Product Carbon Footprint Calculation</h2>
              <p>
                  Activity-based methodology according to ISO 14067 and Greenhouse Gas Protocol
              </p>
              <h2>Disclaimer</h2>
              <p>
                  The results presented in this report relate exclusively to greenhouse gas emissions along the value
                  chain of Koenig&Bauer AG's Rapida printing presses.
                  It is important to note that direct comparisons with products from other manufacturers are not
                  immediately possible. This is due to the fact that there may be differences in the functional unit,
                  the life cycle phases considered and the quality of the underlying data, which may affect the
                  comparability of the results.
                  For further information on the structure of greenhouse gas inventories and explanations of terms,
                  please refer to the documentation of the standard (GHG Protocol Product Life Cycle Accounting and
                  Reporting Standard) at www.ghgprotocol.org.
              </p>
          </div>

      </>
    );
}

export default InfoPage;