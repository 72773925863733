import React from 'react'
import { Button, Card, Form, Input, Space, theme, Typography } from 'antd'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import telusioLogo from '../resources/telusio_blue.png'
import { useAuth } from "../hooks/AuthProvider";

const LoginPage = () => {
    const {
        token: { colorPrimary }
    } = theme.useToken()

    const auth = useAuth()

    const handleSubmit = async ({ email, password }) => {
        await auth.loginAction({ email, password })

        return
    }

    return (
        <>
            <Space
                direction='horizontal' style={{
                height: '100vh',
                width: '100%',
                justifyContent: 'center',
                background: 'linear-gradient(45deg, #001D5B 0%, #0F2363 9.09%, #1B2A6B 18.18%, #253174 27.27%, #2E387C 36.36%, #374089 45.45%, #404996 54.55%, #4952A4 63.64%, #545EBA 72.73%, #606BD1 81.82%, #6C78E8 90.91%, #7885FF 100%)'
            }}
            >
                <Card bordered={false} style={{ width: 400, padding: 24 }}>
                    <div style={{ display: 'flex' }}>
                        <img src={telusioLogo} alt='Telusio Logo' style={{ height: 48, margin: '24px auto' }} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Typography.Title level={3} style={{ color: colorPrimary, margin: '12px auto', fontWeight: 500 }}>Loggen Sie sich ein</Typography.Title>
                    </div>
                    <Form
                        name='login-form'
                        layout='vertical'
                        requiredMark={false}
                        onFinish={handleSubmit}
                    >
                        <Form.Item
                            label='Email'
                            name='email'
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Bitte geben Sie eine gültige Emailadresse ein.'
                                },
                                {
                                    required: true,
                                    message: 'Emailadresse ist ein Pflichtfeld.'
                                }
                            ]}
                        >
                            <Input prefix={<MailOutlined style={{ opacity: 0.25, marginRight: 4 }} />} />
                        </Form.Item>

                        <Form.Item
                            label='Password'
                            name='password'
                            rules={[
                                {
                                    required: true,
                                    message: 'Passwort ist ein Pflichtfeld.'
                                }
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined style={{ opacity: 0.25, marginRight: 4 }} />} />
                        </Form.Item>
                        <div style={{ height: 16 }} />
                        <Form.Item>
                            <Button type='primary' htmlType='submit' block size='large'>
                                Einloggen
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Space>
        </>
    )
}

export default LoginPage
