import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TSiteHeader from "../components/t/TSiteHeader";
import {Button, Col, Divider, Form, Spin, Row, Select} from "antd";
import {useProductConfiguration} from "../hooks/ProductConfigurationProvider";
import WorldImage from "../resources/world_image.png";
import config from "../config";
import {useAuth} from "../hooks/AuthProvider";

const LogisticsFormPage = () => {
    const user = useAuth();
    const navigate = useNavigate();
    const productConfiguration = useProductConfiguration();
    const [isLoading, setLoading] = useState(false);

    const handleCalculationButtonClick = async () => {
        productConfiguration.calculatePCF()
        setLoading(true);
        const response = await fetch(`${config.backendBaseUrl}/event/calculate-pcf`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + user.token
            }
        });

        await setTimeout(() => {
            setLoading(false);
            navigate("/result");
        }, 1000);
    }

    const logisticOptions = productConfiguration.getLogisticOptions();
    const handleDestinationChange = (value) => {
        productConfiguration.setSelectedDestination(value);
        productConfiguration.setDestinationLabel(productConfiguration.getLogisticLabel(value))
    }

    return (
        <>
            <TSiteHeader
                breadCrumbItems={[]}
                pageTitle={"Product Carbon Footprint Calculator"}
            />
            <Form>
                <Divider orientation="left"><h2>3. Choose the preferred delivery location</h2></Divider>
                <Row gutter={16}>
                    <img src={WorldImage} alt='World map' style={{
                        height: '25em',
                        margin: 'auto',
                        marginBottom: '7em'
                    }}/>
                </Row>

                <Row gutter={16}  style={{ paddingLeft: '6em'}}>
                    <Col className="gutter-row" span={6}>
                        <Form.Item name="r_origin">
                        <Select disabled options={[{
                                value: 'Radebeul',
                                label: 'Radebeul'
                            }]} defaultValue="Radebeul" style={{
                                width: 240,
                            }} placeholder="Region">
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <Form.Item name="r_destination">
                            <Select options={logisticOptions} style={{
                                width: 480,
                            }} placeholder="Region" onChange={handleDestinationChange}>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}  style={{ paddingLeft: '6em', marginTop: '5em'}}>
                    <Col className="gutter-row" span={4} >
                        <Button type="default" onClick={() => navigate(-1)}>Back</Button>
                    </Col>
                    <Col className="gutter-row" span={4} offset={20}>
                        <Button type="primary" onClick={handleCalculationButtonClick}>Calculate PCF</Button>
                    </Col>
                </Row>
            </Form>
            <Spin spinning={isLoading} fullscreen />
        </>
    );
}

export default LogisticsFormPage;