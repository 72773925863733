import React, { useEffect, useState } from 'react'
import { Layout, Menu } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  CalculatorOutlined,
  InfoCircleOutlined,
    LogoutOutlined
} from '@ant-design/icons'

import telusioLogoBlue from '../../resources/telusio_blue.png'

const Drawer = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [activePath, setActivePath] = useState()

  useEffect(() => {
    setActivePath(location.pathname.split('/')[1])
  }, [location])

  const navItems = [
    { key: '', icon: <CalculatorOutlined />, label: 'PCF Calculator' },
    { key: '/info', icon: <InfoCircleOutlined />, label: 'Information' },
    { key: '/logout', icon: <LogoutOutlined />, label: 'Log Out' }
  ]

  return (
    <Layout.Sider
      theme='light'
      width={224}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 10
      }}
    >
      <div style={{ width: '100%', display: 'flex' }}>
        <img src={telusioLogoBlue} alt='Telusio Logo' style={{ height: 32, margin: '16px auto' }} />
      </div>
      <Menu
        theme='light'
        selectedKeys={[activePath]}
        items={navItems}
        onClick={(menuItem) => {
        navigate(menuItem.key)
      }}
        style={{
        border: 'none',
          marginTop: 40
      }}
      />
    </Layout.Sider>
  )
}

export default Drawer
