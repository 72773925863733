import React from 'react'
import { Layout, theme } from 'antd'
import { Outlet } from 'react-router-dom'

import Drawer from './Drawer'
import Footer from './Footer'

const Scaffold = () => {
  const {
    token: { colorBgContainer }
  } = theme.useToken()

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Drawer />
        <Layout style={{ marginLeft: 232 }}>
          <Layout.Content>
            <div
              style={{
                padding: '0.1px 24px 24px', // top needs to be set to 0.1 to fix a strange padding bug
                minHeight: '100%',
                background: colorBgContainer
              }}
            >
              <Outlet />
            </div>
          </Layout.Content>
          <Footer />
        </Layout>
      </Layout>
    </>

  )
}
export default Scaffold
