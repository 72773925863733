import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from '../resources/css/form-page.module.css';
import {Col, Divider, Form, Row, Select, theme, Button, Input} from 'antd';
import ProductComponentForm from "../components/ProductComponentForm";
import TSiteHeader from '../components/t/TSiteHeader'
import _ from 'lodash'
import {useProductConfiguration} from "../hooks/ProductConfigurationProvider";

const initializeSelectedComponentObj = (products, product) => {
    if (_.isEmpty(product) || !_.has(products, product)) return {};

    const filteredComponents = _.filter(products[product].p_components, (c) => { return !c.c_is_fixed});

    return _.reduce(filteredComponents, (acc, component) => {
        let defaultValue = component.c_min_value;
        const unitOptions = _.get(component, 'c_unit_options', []);

        if (!_.isEmpty(unitOptions)) {
            defaultValue = unitOptions[0].value;
        }

        return {
            ...acc,
            [component.c_value]: defaultValue
        }
    }, {});
}

const FormPage = () => {
    const navigate = useNavigate();
    const productConfiguration = useProductConfiguration();

    useEffect(() => {
        // Fetch data or perform any initialization
        // This code runs only once, after the component is mounted
        if (_.isEmpty(productConfiguration.selectedProduct)) {
            productConfiguration.setMachineConfigurationText('');
            productConfiguration.setSelectedProduct('');
            (async () => {
                const theProducts = await productConfiguration.readDataFromCSV();
                productConfiguration.setProducts(theProducts);
                productConfiguration.setProductOptions(productConfiguration.getProductOptions(theProducts));
            })();
        }
    }, []);

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();


    const handleProductChange = (value) => {
        if (productConfiguration.selectedProduct !== value) {
            productConfiguration.setPcfResult({
                "show": false,
                "data": {
                    "core": 0.0, // Material, Packaging, Inbound Logistics
                    "outboundLogistic": 0.0,
                    "inboundLogistic": 0.0,
                    "uncertainty": 0.0,
                    "totalWeight": 0.0
                }
            });
        }

        productConfiguration.setSelectedProduct(value)
        productConfiguration.setSelectedComponentObj(initializeSelectedComponentObj(productConfiguration.products, value));
    }

    const handleMachineConfigurationChange = (e) => {
        productConfiguration.setMachineConfigurationText(e.target.value);
    };

    const handleNextStep = () => {
        navigate("/location");
    }

    return (
        <>
            <TSiteHeader
                breadCrumbItems={[]}
                pageTitle={"Product Carbon Footprint Calculator"}
            />
            <Form>
                <Divider orientation="left"><h2>1. Select your product of choice</h2></Divider>
                <Row gutter={16} style={{ minHeight: '150px', paddingLeft: '6em'}}>
                    <Col className="gutter-row" span={6}>
                        <Select
                            value={productConfiguration.selectedProduct}
                            options={productConfiguration.productOptions}
                            style={{
                            width: 240,
                            }}
                            placeholder="Machine Type"
                            onChange={handleProductChange}/>

                    </Col>
                    <Col className="gutter-row" span={6}>
                        <Input
                            value={productConfiguration.machineConfigurationText}
                            addonBefore={
                                !productConfiguration.selectedProduct ? 'RAXXX-' : productConfiguration.products[productConfiguration.selectedProduct].p_key + '-'
                            }
                            placeholder="enter configuration"
                            onChange={handleMachineConfigurationChange}
                            disabled={!productConfiguration.selectedProduct}/>
    
                    </Col>
                    <Col className="gutter-row" span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
                        {
                            !productConfiguration.selectedProduct ? <></> : productConfiguration.products[productConfiguration.selectedProduct].p_image_component
                        }
                    </Col>
                </Row>
                <Divider orientation="left"><h2>2. Select your configuration</h2></Divider>
                <ProductComponentForm
                    products={productConfiguration.products}
                    selectedProduct={productConfiguration.selectedProduct}
                    selectedComponentObj={productConfiguration.selectedComponentObj}
                    setSelectedComponentObj={productConfiguration.setSelectedComponentObj}
                    minHeight={170}/>

                {/*<Divider orientation="left"><h2>4. Final PCF Calculation</h2></Divider>*/}
                <Row gutter={16} style={{marginTop: '5em'}}>
                    <Col className="gutter-row" span={4} offset={20}>
                        <Button type="primary" onClick={handleNextStep}>Select Delivery</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default FormPage;