import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import '../App.css';
import {Col, Row, Divider} from 'antd';
import ResultChart from '../components/ResultChart.tsx'
import TotalPCFCard from '../components/TotalPCFCard.tsx'
import ExtraInfoCard from "../components/ExtraInfoCard";
import {PetrolCarIcon, ForestIcon, AirBaloonIcon} from '../icons/icon.js'
import Comparison from '../components/Comparison.tsx'
import TSiteHeader from '../components/t/TSiteHeader'
import _ from 'lodash'
import { useProductConfiguration } from "../hooks/ProductConfigurationProvider";
import InfoImage from "../resources/calculation_info.png";

const CHART_W = 600
const CHART_H = 500

const ResultPage = () => {
    const navigate = useNavigate();
    const productConfiguration = useProductConfiguration();
    
    useEffect(() => {
        if (!productConfiguration.calculationResult.isCalculated) {
            navigate("/")
        }
    });

    try {
        const {purchasedGoodEmission, totalLogistic, productionEmission, packagingEmission, unspecified} = productConfiguration.calculationResult;
        const totalPCF = purchasedGoodEmission + totalLogistic + productionEmission + packagingEmission + unspecified;
        const totalWeight = productConfiguration.calculationResult.totalWeight / 1000; // in ton

        const data = [
            {
                "key": "purchasedGoodEmission",
                "label": <>Purchased Goods</>
            }, {
                "key": "totalLogistic",
                "label": <>Logistics</>
            }, {
                "key": "productionEmission",
                "label": <>Production</>
            }, {
                "key": "packagingEmission",
                "label": <>Packaging</>
            }, {
                "key": "unspecified",
                "label": <>Not specified</>
            }
        ].map((component, idx) => {
            return {
                idx: idx,
                name: component.label,
                value: Number((productConfiguration.calculationResult[component.key]).toFixed(2))
            }
        })
        // .sort((a, b) => {
        //     if (a.value > b.value) return -1;
        //     if (a.value < b.value) return 1;
        //     return 0;
        // });

        return (
            <>
                <TSiteHeader
                    breadCrumbItems={[]}
                    pageTitle={"Product Carbon Footprint Result"}
                />
                <Row gutter={16} style={{marginBottom: '5em'}}>
                    <Col className="gutter-row" span={12}>
                        <div style={{ textAlign: 'center', padding: '4em'}}>
                            <TotalPCFCard
                                totalPCF={totalPCF}
                                totalWeight={totalWeight}
                                selectedProduct={_.get(productConfiguration.products, productConfiguration.selectedProduct, {"p_label": "-"}).p_label}
                                machineConfigurationText={productConfiguration.machineConfigurationText}
                            />
                        </div>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <div style={{textAlign: 'center', padding: '4em'}}>
                            {productConfiguration.products[productConfiguration.selectedProduct].p_result_image_component}
                        </div>
                    </Col>
                </Row>

                <Divider/>

                <Row gutter={16} style={{marginTop: '5em', marginBottom: '5em'}}>
                    <Col className="gutter-row" span={12}>
                        <div style={{textAlign: 'center', padding: '1em'}}>
                            <img src={InfoImage} alt='Calculation Result Infographic' style={{
                                height: '30em',
                                margin: 'auto'
                            }}/>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <div style={{textAlign: 'center', padding: '4em'}}>
                            <ResultChart data={data} width={CHART_W } height={CHART_H} />
                        </div>
                    </Col>
                </Row>

                <Divider/>

                <Row gutter={16} style={{paddingLeft: '4em', marginTop: '5em'}}>
                    <h2>In comparison: {Math.floor(totalPCF)} t CO<sub>2</sub>...</h2>
                </Row>
                <Row gutter={16} style={{paddingLeft: '4em', marginBottom: '5em'}}>
                    <Col className="gutter-row" span={5}>

                        <Comparison
                            title={"... equals to"}
                            quant={Math.floor(totalPCF * 1000 / 453, 4)}
                            IconComponent={<PetrolCarIcon width={150} height={75}/>}
                            description={<>Car rides from<br/>Lisbon to Düsseldorf</>}
                        />
                    </Col>

                    <Col className="gutter-row" span={5} offset={3}>
                        <Comparison
                            title={"... looks like"}
                            quant={Math.floor(totalPCF)}
                            IconComponent={<AirBaloonIcon width={150} height={75}/>}
                            description={<>Hot Air Balloons <br/>(500 m<sup>3</sup>)</>}
                        />
                    </Col>

                    <Col className="gutter-row" span={5} offset={3}>
                        <Comparison
                            title={"... for the capture"}
                            quant={Math.floor(totalPCF * 1000 / 25)}
                            IconComponent={<ForestIcon width={150} height={75}/>}
                            description={<>Trees must grow<br/>for one year</>}
                        />
                    </Col>
                </Row>

                <Divider/>

                <Row gutter={16} style={{marginTop: '7em', marginBottom: '5em'}}>
                    <Col className="gutter-row" span={24}>
                        <div style={{ paddingLeft: '10em', paddingRight: '10em'}}>
                            <ExtraInfoCard
                                totalPCF={totalPCF}
                                totalWeight={totalWeight}
                                selectedProduct={_.get(productConfiguration.products, productConfiguration.selectedProduct, {"p_label": "-"}).p_label}
                                machineConfigurationText={productConfiguration.machineConfigurationText}
                            />
                        </div>
                    </Col>
                </Row>
            </>
        );
    } catch (e) {
        navigate("/")

        return (<></>)
    }
    

}

export default ResultPage;