import React from 'react'
import { Layout, theme } from 'antd'

const Footer = () => {
  const {
    token: { colorBgContainer }
  } = theme.useToken()

  return (
    <Layout.Footer
      style={{
        padding: '16px 0',
        width: '100%',
        backgroundColor: colorBgContainer,
        textAlign: 'center'
      }}
    >
        All rights reserved. © 2024 Telusio GmbH
    </Layout.Footer>
  )
}

export default Footer
