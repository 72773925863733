import React from 'react'
import {Col, Row} from 'antd';

interface ComparisonType {
    title: string,
    quant: number,
    IconComponent: any,
    description: string
}

const Comparison: React.FC<ComparisonType> = ({title, quant, IconComponent, description}) => {
    return (
        <>
            <Row gutter={16}><h2>{title}</h2></Row>
            <Row gutter={16}>
                <Col className="gutter-row" span={4}>
                    <h2>{quant.toLocaleString()}x</h2>
                </Col>
                <Col className="gutter-row" span={16}>
                    {IconComponent}
                </Col>
            </Row>
            <Row gutter={16}>
                <Col className="gutter-row" span={24}>
                    <h3>{description}</h3>
                </Col>
            </Row>
        </>
    )
}

export default Comparison;