import React, {useContext, createContext, useState} from "react";
import {usePapaParse} from "react-papaparse";
import styles from "../resources/css/form-page.module.css";
import ra106Img from "../resources/RA106.png";
import ra145Img from "../resources/RA145.png";
import DataService from "../data";
import _ from "lodash";

const ProductConfigurationContext = createContext();

const ProductConfigurationProvider = ({ children }) => {
    const [calculationResult, setCalculationResult] = useState({
        isCalculated: false,
        materials: 0,
        inboundLogistics: 0,
        outboundLogistics: 0,
        uncertainty: 0
    });

    const [selectedProduct, setSelectedProduct] = useState('');
    const [products, setProducts] = useState({});
    const [machineConfigurationText, setMachineConfigurationText] = useState('');
    const [destinationLabel, setDestinationLabel] = useState('');
    const [productOptions, setProductOptions] = useState([]);
    const [selectedDestination, setSelectedDestination] = useState('');
    const [selectedComponentObj, setSelectedComponentObj] = useState({});

    const [pcfResult, setPcfResult] = useState({
        "show": false,
        "data": {
            "purchasedGoodEmission": 0.0, // Material, Packaging, Inbound Logistics
            "outboundLogistic": 0.0,
            "inboundLogistic": 0.0,
            "unspecified": 0.0,
            "totalWeight": 0.0,
            "packagingEmission": 0.0,
            "productionEmission": 0.0,
            "totalLogistic": 0.0,
        }
    });

    const { readString } = usePapaParse();

    const readDataFromCSV = async () => {
        const promises = [];
        const products = [
            {
                "key": "RA105",
                "label": "Rapida 105",
                "imageComponent": <div className={styles.animatedImageContainer}><img src={ra106Img} alt='Rapida Picture' style={{ width: '35em', margin: '16px auto' }}/></div>,
                "resultImageComponent": <div><img src={ra106Img} alt='Rapida Picture' style={{ width: '40em' }}/></div>,
                // "uncertainty": 0.05
                "uncertainty": 0.00
            },
            {
                "key": "RA106",
                "label": "Rapida 106",
                "imageComponent": <div className={styles.animatedImageContainer}><img src={ra106Img} alt='Rapida Picture' style={{ width: '35em', margin: '16px auto' }}/></div>,
                "resultImageComponent": <div><img src={ra106Img} alt='Rapida Picture' style={{ width: '40em' }}/></div>,
                // "uncertainty": 0.05
                "uncertainty": 0.00
            },
            {
                "key": "RA106X",
                "label": "Rapida 106 X",
                "imageComponent": <div className={styles.animatedImageContainer}><img src={ra106Img} alt='Rapida Picture' style={{ width: '35em', margin: '16px auto' }}/></div>,
                "resultImageComponent": <div><img src={ra106Img} alt='Rapida Picture' style={{ width: '40em' }}/></div>,
                // "uncertainty": 0.05
                "uncertainty": 0.00
            },
            {
                "key": "RA145",
                "label": "Rapida 145",
                "imageComponent": <div className={styles.animatedImageContainer}><img src={ra145Img} alt='Rapida Picture' style={{ width: '35em', margin: '16px auto' }}/></div>,
                "resultImageComponent": <div><img src={ra145Img} alt='Rapida Picture' style={{ width: '40em' }}/></div>,
                "uncertainty": 0.06
            },
            {
                "key": "RA164",
                "label": "Rapida 164",
                "imageComponent": <div className={styles.animatedImageContainer}><img src={ra145Img} alt='Rapida Picture' style={{ width: '35em', margin: '16px auto' }}/></div>,
                "resultImageComponent": <div><img src={ra145Img} alt='Rapida Picture' style={{ width: '40em' }}/></div>,
                "uncertainty": 0.06
            }
        ];

        for (const product of products) {
            const response = await fetch('/data/' + product.key + '.csv');
            const csvStr = await response.text();
            const theProducts = [];

            promises.push(new Promise((resolve) => {
                readString(csvStr, {
                    worker: true,
                    complete: (result) => {
                        resolve(DataService.readProductFromCsvResult(product, result.data));
                    }
                });
            }));

        }

        return _.keyBy(await Promise.all(promises), 'p_key');
    }

    const calculatePCF = () => {
        const validationResult = DataService.validateInput(products, selectedProduct, selectedComponentObj, selectedDestination);

        if (!validationResult.isValid) {
            setPcfResult({
                "show": false,
                "data": {
                    "purchasedGoodEmission": 0.0, // Material, Packaging, Inbound Logistics
                    "outboundLogistic": 0.0,
                    "inboundLogistic": 0.0,
                    "unspecified": 0.0,
                    "totalWeight": 0.0,
                    "packagingEmission": 0.0,
                    "productionEmission": 0.0
                }
            });

            return;
        }

        const result = DataService.calculatePCF(products, selectedProduct, selectedComponentObj, selectedDestination, products[selectedProduct].p_uncertainty);
        setPcfResult({
            "show": true,
            "data": {
                "purchasedGoodEmission": result.purchasedGoodEmission,
                "outboundLogistic": result.outboundLogistic,
                "inboundLogistic": result.inboundLogistic,
                "unspecified": result.unspecified,
                "totalWeight": result.totalWeight,
                "packagingEmission": result.packagingEmission,
                "productionEmission": result.productionEmission
            }
        });

        setCalculationResult({
            ...result,
            isCalculated: true
        });
    }

    return (
        <ProductConfigurationContext.Provider value={{
            productOptions,
            setProductOptions,
            selectedDestination,
            setSelectedDestination,
            selectedComponentObj,
            setSelectedComponentObj,
            pcfResult,
            setPcfResult,
            readDataFromCSV,
            calculationResult,
            setCalculationResult,
            selectedProduct,
            setSelectedProduct,
            products,
            setProducts,
            machineConfigurationText,
            setMachineConfigurationText,
            destinationLabel,
            setDestinationLabel,
            calculatePCF,
            getProductOptions: DataService.getProductOptions,
            getLogisticOptions: DataService.getLogisticOptions,
            getLogisticLabel: DataService.getLogisticLabel
        }}>
            {children}
        </ProductConfigurationContext.Provider>
    )
}

export default ProductConfigurationProvider;

export const useProductConfiguration = () => {
    return useContext(ProductConfigurationContext);
};