import React from 'react';
import { getComponentOptions } from '../data';
import {Col, Row, Select, InputNumber, Form, Checkbox} from 'antd';
import {useProductConfiguration} from "../hooks/ProductConfigurationProvider";
const _ = require('lodash');

interface ComponentObj {
    [key: string]: any;
}

interface ProductComponentFormProps {
    products: any;
    selectedProduct: any; // Define the type according to your actual data structure
    selectedComponentObj: ComponentObj;
    setSelectedComponentObj: React.Dispatch<React.SetStateAction<ComponentObj>>;
    minHeight: number;
}

const ProductComponentForm: React.FC<ProductComponentFormProps> = ({products, selectedProduct, selectedComponentObj, minHeight, setSelectedComponentObj}) => {
    const productConfiguration = useProductConfiguration();

    const handleComponentChange = (componentKey: string) => (value: React.SetStateAction<any>) => {
        setSelectedComponentObj({
            ...selectedComponentObj,
            [componentKey]: value
        });
    }
    
    const handleCheckBoxChange = (componentKey: string) => (e: any) => {
        setSelectedComponentObj({
            ...selectedComponentObj,
            [componentKey]: e.target.checked ? 1 : 0
        });
    }

    const handleSelectChange = (componentKey: string) => (newValue: any) => {
        setSelectedComponentObj({
            ...selectedComponentObj,
            [componentKey]: newValue
        });
    }
    
    const getForm = (component: any) => {
        if (_.get(component, 'isBinary', false)) {
            return <Checkbox
                onChange={handleCheckBoxChange(component.componentName)}
                checked={_.get(productConfiguration.selectedComponentObj, component.componentName, 0) === 1}
            />
        } else if (_.get(component, 'unitOptions', []).length === 0) {
            return <InputNumber
                min={component.minValue}
                max={component.maxValue}
                controls={true}
                size={"middle"}
                value={_.has(selectedComponentObj, component.componentName) ? _.get(selectedComponentObj, component.componentName) : 0}
                onChange={handleComponentChange(component.componentName)}
                addonAfter={"unit(s)"}
                style={{ width: '9em' }}
            />
        } else {
            const options = _.get(component, 'unitOptions', []);
            return <Select options={options} defaultValue={options[0].value} onChange={handleSelectChange(component.componentName)} />
        }
    }

    return ( 
        <div className="ProductComponentForm" style={{ minHeight: minHeight + 'px' }}>
            {
            getComponentOptions(products, selectedProduct).map((componentChunk: any, groupIdx: number) => {
                return (
                    <Row gutter={[16,24]} key={"rowgroup-" + groupIdx} style={{ paddingLeft: '6em'}}>
                        {
                            componentChunk.map((component: any, colIdx: number) => {
                                 return (
                                     component ?
                                     <Col className="gutter-row" span={6} offset={colIdx * (3)} key={"col-" + groupIdx + "-" + colIdx} style={{ textAlign: 'right', paddingRight: '4em' }}>
                                         <Form.Item label={<span style={{ fontWeight: 'bold' }}>{component.componentLabel}</span>} key={"form-item-" + component.value}>
                                             { getForm(component) }
                                         </Form.Item>
                                     </Col> :
                                         <Col className="gutter-row" span={6} offset={colIdx * (3)} key={"col-" + groupIdx + "-" + colIdx} style={{ textAlign: 'right' }}>
                                         </Col>
                                 )
                            })
                        }
                    </Row>
                    )
            })
            }
        </div>
    )
}

export default ProductComponentForm;