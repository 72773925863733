import styles from "../resources/css/pie-chart.module.css";
import React, {useState} from "react";
import _ from "lodash";
import {PieChart, Pie, Sector, ResponsiveContainer, Cell, Legend} from 'recharts';

interface ChartDataItem {
    name: string,
    value: number,
    idx: number
}

interface ResultChartProps {
    width: number,
    height: number,
    data: ChartDataItem[];
}

const sectorColors = [
    "#001d5b",
    "#c1dfc3",
    "#374a9a",
    "#adcc5f",
    "#e2e7ef",
    "#e2e3e3"
]

// @ts-ignore
const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10 / 3) * cos;
    const sy = cy + (outerRadius + 10 / 3) * sin;
    const mx = cx + (outerRadius + 30 / 3) * cos;
    const my = cy + (outerRadius + 30 / 3) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={sectorColors[0]}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 3}
                outerRadius={outerRadius + 5}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
                {`${value} t CO₂e`}
            </text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18 * 1} textAnchor={textAnchor} fill="#999">
                {`(${(percent * 100).toFixed(1)}%)`}
            </text>
        </g>
    );
};


const ResultChart: React.FC<ResultChartProps> = ({width, height, data}) => {
    const [state, setState] = useState({
        activeIndex: 0,
    });
    const total = _.sumBy(data, (o) => { return o.value; })

    // @ts-ignore
    const onPieEnter = (_, index) => {
        setState({
            activeIndex: index,
        });
    };

    const legendFormatter = (label: string, entry: any, index: number) => {
        const theData = data[index]
        const percentage = (theData.value / total * 100).toLocaleString('en-EN', { minimumFractionDigits: 0, maximumFractionDigits: 1 })

        return (
            <span style={{color: '#001d5b'}}>
                {theData.name} - {`${percentage}%`}
            </span>
        );
    }

    return (
        <PieChart width={width} height={height}>
            <Pie
                activeIndex={state.activeIndex}
                activeShape={renderActiveShape}
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={120}
                outerRadius={140}
                fill="#020246"
                dataKey="value"
                onMouseEnter={onPieEnter}
            >
                {
                    data.map((entry, index) => {
                        return <Cell key={`cell-${index}`}
                                     fill={sectorColors[index]}/>
                    })
                }
            </Pie>
            <Legend
                margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                formatter={legendFormatter}
                layout={'vertical'}
                align={'center'}
            />
        </PieChart>
    );
}
export default ResultChart;
