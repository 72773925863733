import React, {useState} from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import FormPage from './pages/FormPage'
import ResultPage from './pages/ResultPage'
import InfoPage from './pages/InfoPage'
import LoginPage from './pages/LoginPage'
import Scaffold from './components/scaffold/index'
import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./components/PrivateRoute";
import LogoutRoute from "./components/Logout";
import LogisticsFormPage from "./pages/LogisticsFormPage";
import ProductConfigurationProvider from "./hooks/ProductConfigurationProvider";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
          <ProductConfigurationProvider>
        <Routes>
          <Route path='/login' element={<LoginPage/>} />
          <Route element={<Scaffold />}>
            <Route path='/info' element={<InfoPage/>} />
            <Route path='/logout' element={<LogoutRoute/>} />
              <Route element={<PrivateRoute />}>
                  <Route path='/' element={<FormPage/>} />
                  <Route path='/location' element={<LogisticsFormPage/>} />
                  <Route path='/result' element={<ResultPage/>} />
              </Route>
          </Route>
        </Routes>
        </ProductConfigurationProvider>
      </AuthProvider>
    </BrowserRouter>
    )
}

export default App;
