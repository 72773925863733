import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";

const LogoutRoute = () => {
    const user = useAuth();
    user.logOut()
    return <Navigate to="/login"/>;
};

export default LogoutRoute;