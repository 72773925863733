import React from 'react';
import _ from 'lodash';
import styles from '../resources/css/totalPCFCard.module.css';

interface TotalPCFCardProps {
    totalPCF: number,
    totalWeight: number,
    selectedProduct: string,
    machineConfigurationText: string,
}

const TotalPCFCard : React.FC<TotalPCFCardProps> = ({
    totalPCF,
    totalWeight,
    selectedProduct,
    machineConfigurationText
}) => {
    return (
        <div className={styles.card}>
            <div className={styles.titleText}>
                <h3>Product Carbon Footprint of {selectedProduct}{ _.isEmpty(machineConfigurationText) ? '' : `-${machineConfigurationText}` }</h3>
            </div>
            
            <div className={styles.mainText}>
                {totalPCF.toFixed(2)}
            </div>
            
            <div className={styles.unitText}>
                t CO<sub>2</sub>e
            </div>

            {/*<div className={styles.additionalInfo}>*/}
            {/*    <div>*/}
            {/*    Overall weight {totalWeight.toFixed(2)} to*/}
            {/*    </div>*/}

            {/*    <div>*/}
            {/*        {(totalPCF / totalWeight).toFixed(2)} to CO<sub>2</sub>e pro to material of the machine*/}
            {/*    </div>*/}

            {/*    <div>*/}
            {/*        With {uncertaintyInPercent.toFixed(0)}% of uncertainty*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default TotalPCFCard;